<template>
  <div class="uello-app-users-management h-100 mt-5">
    <div class="d-flex justify-content-end align-items-center px-3 pt-4 mb-3">
      <uello-button
        color="red"
        icon="account"
        :text="$t('form.buttons.submitButtonCreateUser')"
        @click="createUser"
      />
    </div>
    <dataset-ag-grid
      ref="usersManagement"
      :setData="() => {}"
      :defs="defs"
      :rowData="rowData"
      :dataComponents="dataComponents"
      :dataFwComponents="fwComponents"
    />
    <transition name="fade" mode="out-in">
      <lateral-slider
        v-if="createUserOpen"
        @close="closeLateralSlide"
        :title="$t(`form.buttons.${submitButtonLabel}`)"
      >
        <b-form class="user-form p-2" @submit.prevent="handleSubmit" autocomplete="off">
          <b-form-group :label="($t('form.labels.name') + ':') | capitalize" label-for="name-input">
            <b-form-input
              id="name-input"
              v-model="form.name"
              :placeholder="$t('form.placeholders.name')"
              size="sm"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="($t('form.labels.email') + ':') | capitalize"
            label-for="email-input"
          >
            <b-form-input
              id="email-input"
              name="new-email"
              type="email"
              v-model="form.email"
              :placeholder="$t('form.placeholders.userEmail')"
              size="sm"
              autocomplete="off"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            :label="($t('form.labels.userStatus') + ':') | capitalize"
            label-for="status-input"
          >
            <b-form-select
              id="status-input"
              v-model="form.active"
              :options="options"
              size="sm"
              required
            ></b-form-select>
          </b-form-group>
          <b-button class="w-100 mt-2" variant="primary" size="sm" type="submit">{{
            $t(`form.buttons.${submitButtonLabel}`)
          }}</b-button>
        </b-form>
        <transition name="fade" mode="out-in">
          <follow-ag-grid-loading-overlay v-if="loading" />
        </transition>
      </lateral-slider>
    </transition>
    <movidesk-modal
      :hasButton="movideskHasButton"
      :is-open="movideskIsOpen"
      :prefill="{}"
      @close="closeMovidesk"
      @open="openMovidesk"
    />
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';

import api from '@/services/api';
import { safeStorage } from '@/utils';
import { movidesk } from '@/mixins';
import { UelloButton } from '@uello/componentello';
import i18n from '@/i18n';

import UserNameCellRender from './components/u-users-management/UserNameCellRender.vue';
import UserStatusCellRender from './components/u-users-management/UserStatusCellRender.vue';

export default {
  name: 'uello-app-users-management',
  mixins: [movidesk],
  components: {
    FollowAgGridLoadingOverlay: () =>
      import('./components/u-follow/FollowAgGridLoadingOverlay.vue'),
    MovideskModal: () => import('@/components/MovideskTicketFormModal.vue'),
    UelloButton,
  },
  data() {
    return {
      helpLink: process.env.VUE_APP_MOVIDESK_HELP_USERS,
      movideskHasButton: true,
      createUserOpen: false,
      submitButtonLabel: 'submitButtonEditUser',
      loading: false,
      create: false,
      userId: null,
      form: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        active: null,
      },
      options: [
        { text: this.$t('form.placeholders.userStatus'), value: null },
        { text: this.$t('form.selectValues.active'), value: true },
        { text: this.$t('form.selectValues.inactive'), value: false },
      ],
      createForm: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        active: null,
      },
      rowData: [],
      dataComponents: {
        UserNameCellRender,
        UserStatusCellRender,
      },
      fwComponents: {
        userNameCellRender: 'UserNameCellRender',
        userStatusCellRender: 'UserStatusCellRender',
      },
      defs: [
        {
          headerName: 'Id',
          field: 'id',
          sortable: false,
          filter: false,
          hide: true,
        },
        {
          headerName: i18n.t('form.labels.name'),
          field: 'name',
          sortable: true,
          filter: false,
          cellRenderer: 'userNameCellRender',
        },
        {
          headerName: i18n.t('form.labels.email'),
          field: 'email',
          sortable: true,
          filter: false,
        },
        {
          headerName: i18n.t('agGrid.columnDefs.status'),
          field: 'active',
          sortable: true,
          filter: false,
          cellRenderer: 'userStatusCellRender',
          editable: true,
        },
        {
          headerName: i18n.t('agGrid.columnDefs.lastLogin'),
          field: 'lastLoginAt',
          sortable: true,
          // filter: 'dateFilter',
          filter: false,
        },
      ],
      rowSelected: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const { data: response } = await api.get('/users');

      this.rowData = response.data.map(data => ({
        ...data,
        name: data.name ? data.name : safeStorage.getItem('config').operations[0].partner,
        lastLoginAt: this.dateFormat(data),
      }));
    },
    dateFormat(data) {
      return data.lastLoginAt
        ? format(parseISO(data.lastLoginAt.date), "dd/MM/yyyy' - 'HH:mm:ss")
        : 'N/A';
    },
    async handleSubmit() {
      this.loading = true;

      try {
        await api[this.create ? 'post' : 'put'](
          `/users${this.create ? '' : `/${this.userId}`}`,
          this.form
        );

        this.closeLateralSlide();

        await this.getData();

        this.$toast.success(
          this.$t(`messages.${this.create ? 'create' : 'edit'}UserSuccess`, {
            name: this.form.name,
          })
        );
      } catch (error) {
        this.$toast.error(
          this.$t(`messages.${this.create ? 'create' : 'edit'}UserError`, {
            name: this.form.name,
          })
        );
      }

      this.loading = false;
    },
    setSelectedRow(row) {
      const { name, email, active } = row;
      this.form = { ...this.form, name, email, active };
      this.submitButtonLabel = 'submitButtonEditUser';
      this.createUserOpen = true;
      this.create = false;
      this.userId = row.id;
    },
    closeLateralSlide() {
      this.form = { ...this.createForm };
      this.createUserOpen = false;
      this.$refs.usersManagement.gridApi.deselectAll();
    },
    createUser() {
      this.form = { ...this.createForm };
      this.submitButtonLabel = 'submitButtonCreateUser';
      this.createUserOpen = true;
      this.create = true;
      this.userId = null;
    },
  },
};
</script>

<style></style>
