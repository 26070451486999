<template>
  <div class="user-status-template d-flex align-items-center h-100">
    <span
      class="status-bullet rounded flex-1"
      :class="`bg-${status} text-${this.params.value ? 'white' : 'dark'}`"
    >
      {{ this.params.value ? 'Ativo' : 'Inativo' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'user-status-template',
  data() {
    return {
      status: null,
    };
  },
  beforeMount() {
    this.status = this.params.value ? 'success' : 'warning';
  },
};
</script>

<style lang="scss">
.user-status-template {
  .status-bullet {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
  }
}
</style>
