<template>
  <div class="user-name-cell-render d-flex align-items-center">
    <div class="avatar" :style="avatarStyle">
      <span class="user-initials" :style="{ color: avatarStyle.color }">{{ initials }}</span>
    </div>
    <span class="text-dark-darken user-name">{{ params.value || 'UE' }}</span>
  </div>
</template>

<script>
// import { readableColor } from 'polished';
import { nameInitials, randomColor, safeStorage } from '@/utils';

export default {
  name: 'user-name-cell-render',
  computed: {
    initials() {
      const name = this.params.value
        ? this.params.value
        : safeStorage.getItem('config').operations[0].partner;
      return this.params.value ? nameInitials(this.params.value) : nameInitials(name);
    },
    avatarStyle() {
      const color = randomColor();

      return { background: color, color: '#fff' };
      // readableColor(color, '#444', '#fff')
    },
  },
  methods: {
    refresh(params) {
      if (params.value !== this.params.value) {
        this.params = params;
      }
    },
  },
};
</script>

<style lang="scss">
.user-name-cell-render {
  .avatar {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    .user-initials {
      font-weight: bold;
    }
  }

  span.user-name {
    margin-left: 10px;
    font-weight: bold;
  }
}
</style>
